import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServiceService } from '../services/user/user-service.service';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  countryList = [];
  showMessage: boolean = false;
  error;
  get forgotpasswordDataForm() { return this.forgotPasswordForm.controls; };
  constructor(private fb: FormBuilder, private router: Router, private userServiceService: UserServiceService) { 
    this.getCountryData();
  }


  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      mobile: [null, Validators.required],
      country_code: [null, Validators.required]
    });
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.userName === 'admin' && user.password === 'admin') {
      this.router.navigate(['/pages/add-user']);
    }
  }

  //Get country list
  getCountryData() {
    this.userServiceService.getCountry()
    .subscribe(
      (data: any) => {
        const country = data.data.countries;
        data.data.countries.forEach(element => {
          this.countryList.push({id:element.phonecode, value:element.name + " ("+ element.phonecode+")"});
        });
       this.forgotPasswordForm.get('country_code').setValue('+91');
      },
      error => {
        
      },
    );

  }
  
  /**
   * Navigate to login
   * @param event 
   */
  navigate(event:Event) {
    event.preventDefault();
    this.router.navigate(['/login']);
  }

  /**
   * Ask for otp for forgot password
   */
  forgotPassword(event:Event) {
    event.preventDefault();
    this.showMessage = false;
    if (!this.forgotPasswordForm.valid) {
      this.showMessage = true;
      return;
    }

    const forgotPasswordFormObject: any = {};
    forgotPasswordFormObject.mobile = this.forgotPasswordForm.get('mobile').value;
    forgotPasswordFormObject.country_code = this.forgotPasswordForm.get('country_code').value;
    // Call forgot password service for request new otp of reset password.
    this.userServiceService.getForgotPasswordOtp(forgotPasswordFormObject).subscribe(
        (data: any) => {
          if(data.error == false) {
            
            setTimeout(() => {
              this.router.navigate(['/reset-forgot-password']);
            }, 100)
          } else {
            this.error = data.data.message;
          }
        },
        error => {
          this.error = error.error.message;
        }
      )
    
  }

}
