import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'timeZone'
})
export class TimeZonePipe implements PipeTransform {
  transform(date: Date, userTimezone: string): string {
    // input date is in UTC
    const utcDate = moment.utc(date);

    // Convert to user's timezone
    const convertedDate = utcDate.tz(userTimezone);

    // Format the date
    const formattedDate = convertedDate.format('YYYY-MM-DD HH:mm:ss');

    return formattedDate;
  }
}
