import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.baseUrl;
  // memberUrl: string = this.baseUrl+'members/memberList';
  memberUrl: string = this.baseUrl + 'newUsers/userList';
  userDropDownUrl: string = this.baseUrl + 'newUsers/getAllUserList';
  getMemberByIdUrl: string = this.baseUrl + 'newUsers/getUserById';
  // loginUserUrl: string = this.baseUrl+'agent/login';
  loginUserUrl: string = this.baseUrl + 'userAuthentication/login-admin';
  logoutUserUrl: string = this.baseUrl + 'userAuthentication/logout';
  getCountryUrl: string = this.baseUrl + 'newUsers/signupRequireData';
  // changeMemberStatusUrl: string = this.baseUrl+'members/changeMemberStatus';
  changeMemberStatusUrl: string = this.baseUrl + 'newUsers/changeUserstatus';
  getMemberSelectionsUrl: string = this.baseUrl + 'members/getMemberSelections';
  addMemberUrl: string = this.baseUrl + 'members/addMember';
  editMemberUrl: string = this.baseUrl + 'members/editMember';
  getForgetPasswordOtpUrl: string = this.baseUrl + 'newUsers/getOTP';
  resetForgetPasswordViaOtpUrl: string = this.baseUrl + 'newUsers/updatePasswordWithOtp';
  getUserApprovalStagesUrl: string = this.baseUrl + 'newUsers/getUserApprovalStages';
  approveUserUrl: string = this.baseUrl + 'newUsers/approveUser';
  getUserMembershipPlanUrl: string = this.baseUrl + 'MembershipPlans/listMembership';
  assignMembershipPlanToUserUrl: string = this.baseUrl + 'newUsers/assignMembershipPlan';
  assignRoleToUserUrl: string = this.baseUrl + 'newUsers/assignPermissionRole';
  getPermissionRolesUrl:string = this.baseUrl+'role/getAllRoles';
  changeUserStatuAdminsUrl: string = this.baseUrl + 'newUsers/changeUserStatusByAdmin';
  activeUserDropDownUrl: string = this.baseUrl + 'newUsers/getActiveUserList';
  activeInvestorDropDownUrl: string = this.baseUrl + 'newUsers/getActiveInvestorList';
  changeMembershipExpireDateUrl: string = this.baseUrl + 'newUsers/changeMembershipExpireDate';
  
  getMember(searchString = "", perPage= 10, pageNumber = 0) {
    try {
      let param = {
        searchString: searchString,
        perPage: perPage.toString(),
        pageNumber: pageNumber.toString(),
      };
      if(!param.searchString)
        delete param.searchString;

        return this.http.get(this.memberUrl, {
        params: param
      });
    } catch (error) {

    }
  }
  
  loginUser(userData, deviceInfo) {
    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'app-version': '0.2.8',//deviceInfo['app-version'],
        'device-os': deviceInfo['device-os'],
        'device-os-version': deviceInfo['device-os'],
        'device-name': deviceInfo['device-name'],
        'timezone': deviceInfo['timezone'],
      });
      return this.http.post(this.loginUserUrl, userData, {headers: headers});
    } catch (error) {

    }
  }
  
  logoutUser() {
    try {
      return this.http.get(this.logoutUserUrl);
    } catch (error) {

    }
  }

  changeMemberStatus(data) {
    try {
      return this.http.post(this.changeMemberStatusUrl, data);
    } catch (error) {

    }
  }

  getMemberSelections() {
    try {
      return this.http.get(`${this.getMemberSelectionsUrl}`);
    } catch (error) {

    }
  }

  addMember(memberData) {
    try {
      return this.http.post(this.addMemberUrl, memberData);
    // return this.http.get(this.customersUrl);
    } catch (error) {

    }
  }
  editMember(memberData) {
    try {
      return this.http.post(this.editMemberUrl, memberData);
    } catch (error) {

    }
  }
  
  getMemberById(mid) {
    try {
      return this.http.get(this.getMemberByIdUrl + "?mid=" + mid);
    } catch (error) {

    }
  }

  /**
   * Set Forgot password otp
   * @param forgotPasswordDataObj forgot password user mobile number 
   */
  getForgotPasswordOtp(forgotPasswordDataObj) {
    try {
      return this.http.post(this.getForgetPasswordOtpUrl, forgotPasswordDataObj);
    } catch (error) {

    }
  }

  /**
   * Reset Forgot password using otp
   * @param resetPasswordDataObj reset forgot password using otp 
   */
  resetForgotPasswordViaOtp(resetPasswordDataObj) {
    try {
      return this.http.post(this.resetForgetPasswordViaOtpUrl, resetPasswordDataObj);
    } catch (error) {

    }
  }

  /**
   * Get Approval stage
   */
  getUserApprovalStages() {
    try{
      return this.http.get(this.getUserApprovalStagesUrl);
    }catch(error){

    }
  }

  /**
   * Approve user by mid
   */
  approveUserByAdmin(postData) {
    try{
      return this.http.post(this.approveUserUrl,postData);
    }catch(error){

    }
  }

  /**
   * Get User Membership Plan
   */
  getUserMemberrshipPlan() {
    try{
      return this.http.get(this.getUserMembershipPlanUrl);
    }catch(error){

    }
  }


  /**
   * Assign User Membership Plan
   */
  assignMembershipPlanToUser(postData) {
    try{
      return this.http.post(this.assignMembershipPlanToUserUrl,postData);
    }catch(error){

    }
  }


  /**
   * Assign Permission Role to User
   */
  getPermissionRoles() {
    try{
      return this.http.get(this.getPermissionRolesUrl);
    }catch(error){

    }
  }
  /**
   * Assign Permission Role to User
   */
  assignPermissionRoleToUser(postData) {
    try{
      return this.http.post(this.assignRoleToUserUrl,postData);
    }catch(error){

    }
  }

  /**
   * Get User Dropdown List
   */
  getUserDropdown() {
    try{
      return this.http.get(this.userDropDownUrl);
    }catch(error){
      return error;
    }

  }

  /**
   * Change user's active status
   * @param data 
   */
  changeMemberStatusAdmin(data) {
    try{
      return this.http.post(this.changeUserStatuAdminsUrl, data);
    }catch(error){
      return error;
    }
  }

  /**
   * Get country list for login
   */
  getCountry() {
    try{
      return this.http.get(this.getCountryUrl);
    }catch(error){
      return error;
    }
  }

  /**
   * Get Active User Dropdown List
   */
  getActiveUserDropdown() {
    try{
      return this.http.get(this.activeUserDropDownUrl);
    }catch(error){
      return error;
    }

  }

  /**
   * Change user's membership status
   * @param data 
   */
  changeMembershipExpireDate(data) {
    try{
      return this.http.post(this.changeMembershipExpireDateUrl, data);
    }catch(error){
      return error;
    }
  }

  /**
   * Get Active Investor Dropdown List
   */
  getActiveInvestorListDropDown() {
    try{
      return this.http.get(this.activeInvestorDropDownUrl);
    }catch(error){
      return error;
    }

  }


}
