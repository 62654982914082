import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServiceService } from '../services/user/user-service.service';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  error;
  countryList = [];
  showMessage: boolean = false;
  get resetpasswordDataForm() { return this.resetPasswordForm.controls; }
  constructor(private fb: FormBuilder, private router: Router, private userServiceService: UserServiceService) {
    this.getCountryData();
  };
 
  ngOnInit() {

    this.resetPasswordForm = this.fb.group({
      country_code: [null, Validators.required],
      contact: [null, Validators.required],
      otp: [null, Validators.required],
      password: [null, Validators.required],
      confirm_password: [null, Validators.required],
    });
  }

  //Get country list
  getCountryData() {
    this.userServiceService.getCountry()
    .subscribe(
      (data: any) => {
        const country = data.data.countries;
        data.data.countries.forEach(element => {
          this.countryList.push({id:element.phonecode, value:element.name + " ("+ element.phonecode+")"});
        });
       this.resetPasswordForm.get('country_code').setValue('+91');
      },
      error => {
        
      },
    );

  }

  /**
   * Navigate to login
   * @param event 
   */
  navigate(event:Event) {
    event.preventDefault();
    this.router.navigate(['/login']);
  }

  /**
   * Reset Forgot password via otp
   */
  resetPassword(event:Event) {
    this.showMessage = false;
    if (!this.resetPasswordForm.valid) {
      this.showMessage = true;
      return;
    }
    if (this.resetPasswordForm.get('confirm_password').value == this.resetPasswordForm.get('password').value) {
        const resetPasswordFormObject: any = {};
        resetPasswordFormObject.country_code = this.resetPasswordForm.get('country_code').value;
        resetPasswordFormObject.contact = this.resetPasswordForm.get('contact').value;
        resetPasswordFormObject.otp = this.resetPasswordForm.get('otp').value;
        resetPasswordFormObject.password = this.resetPasswordForm.get('password').value;
        // Call reset password service for reset password.
        this.userServiceService.resetForgotPasswordViaOtp(resetPasswordFormObject).subscribe(
            (data: any) => {
              if(data.error == false) {
                setTimeout(() => {
                  this.router.navigate(['/login']);
                }, 100)
              } else {
                this.error = data.data.message;
              }
            },
            error => {
              this.error = error.error.message;
            }
          )
    } else {
      this.error = 'Password does not match confirm password.';
    }
  }
}
