import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class TokenInspectorService {
  constructor(
    private router: Router,
    private toastrService: NbToastrService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler){
    let token = localStorage.getItem('JWTtoken');
    if(token) {
      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
       tap((event: HttpEvent<any>) => {
         if (event instanceof HttpResponse) {
            if (event.body.error == true && (event.body.errorCode == 1001 || event.body.errorCode == 401)) {
              const message  = event.body.errorCode == 1001 ? event.body.message : event.body.data.message ||event.body.message 
              setTimeout(()=>{
                this.toastrService.danger(message, "Error");
                this.router.navigate(['login']);
              },100);
            }
         }
       }, (err: any) => {
         if (err instanceof HttpErrorResponse) {
           if (err.status === 403 || err.status === 401) {
             setTimeout(()=>{
              this.toastrService.danger(err.error.message || err.error.data.message ,"Error");
              this.router.navigate(['login']);
             },100);
           } else if(err.status === 415) {
              this.router.navigate(['login']);    
           }
         }
       })
   );

  }
}
