import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServiceService } from '../services/user/user-service.service';
import { DatePipe } from '@angular/common';
import * as momenttimezone from "moment-timezone";
@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [DatePipe],
})
export class LoginComponent implements OnInit {
 
  loginForm: FormGroup;
  loading: boolean = false;
  showMessage: boolean = false;
  countryList = [];
  error;
  deviceInfo:any = {};
    
  get loginDataForm() { return this.loginForm.controls; }

  constructor(
    private fb: FormBuilder, 
    private router: Router, 
    private userServiceService: UserServiceService,
    public datepipe: DatePipe,
  ) {
    this.getCountryData();
    var offset = momenttimezone.tz.guess(); //this.datepipe.transform(new Date(), 'zzzz');
    
    this.deviceInfo = {
      'app-version': navigator.appVersion,
      'device-os': navigator.platform.split(" ")[0],
      'device-os-version': navigator.platform.split(" ")[1],
      'device-name': navigator.appName,
      'timezone' : offset,
    };
  }
  
  

  ngOnInit() {
    this.loginForm = this.fb.group({
      mobile: [, Validators.required],
      country_code: [, Validators.required],
      password: [, Validators.required]
    });
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.userName === 'admin' && user.password === 'admin') {
      this.router.navigate(['/pages/add-user']);
    }

  }

  getCountryData() {
    this.userServiceService.getCountry()
    .subscribe(
      (data: any) => {
        const country = data.data.countries;
        data.data.countries.forEach(element => {
          this.countryList.push({id:element.phonecode, value:element.name + " ("+ element.phonecode+")"});
        });
       this.loginForm.get('country_code').setValue('+91');
      },
      error => {
        
      },
    );

  }

  async login(event:Event) {
    event.preventDefault();
    this.showMessage = false;
    if (!this.loginForm.valid) {
      this.showMessage = true;
      return;
    }
    this.loading = true;
    this.userServiceService.loginUser(this.loginForm.value, this.deviceInfo)
    .subscribe(
        (data: any) => {
          if(data.error == false) {
            localStorage.setItem('user', JSON.stringify(data.data));
            localStorage.setItem('JWTtoken', data.data.token);
            let permissions = [];
            for (let i=0;i<data.data.permissions.length;i++) {
              permissions.push(data.data.permissions[i].permission_name);
            }
            localStorage.removeItem('permissions');
            localStorage.setItem('permissions', JSON.stringify(permissions));
            //window.location.href = "/pages/dashboard";
            this.router.navigate(['/pages/dashboard']);
          } else {
            this.loading = false;
            this.error = (data.data.message) ? data.data.message : data.message;
          }
        },(error) =>  {
          this.loading = false;
          this.error =  (error.error.message) ? error.error.message : error.error.data.message ;
        }
      )
  }

  /**
   * Navigate to forgot password
   * @param event 
   */
  navigate(event:Event) {
    event.preventDefault();
    this.router.navigate(['/forgot-password']);
  }

  get username() {
    return this.loginForm.get('userName');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
